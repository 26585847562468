<template>
	<div class="page">
		<div class="content">
			<div class="title-flexBox">
				<div class="titleBox">
					<div class="titTop">Brand activities</div>
					<div class="titBottom">{{lang==6?'品牌活动':'Brand activities'}}</div>
				</div>
				<div class="right">
					<selectBox @change="page=1;getActivityList($event)"></selectBox>
					<el-select clearable @change="page=1;getActivityList()" v-model="brand_id" filterable
						:placeholder="lang==6?'品牌选择':'brand choice'" :popper-append-to-body="false">
						<el-option v-for="item in brandList" :key="item.id" :label="item.title" :value="item.id">
						</el-option>
					</el-select>
					<!-- <el-select placeholder="品牌选择"></el-select> -->
				</div>
			</div>
			<div class="bottom-content" ref="list">
				<div class="box" v-for="(group,index) in list" :key="index"
					:style="group.length==1 ? 'height:560px' : group.length==2 ? 'height:1100px' : group.length==0 ? 'height:100px' : ''">
					<div class="item" v-for="(item,j) in group" :key="j" ref="item"
						@click="$router.push(`/activity/details/${item.id}?type=2`)"
						:style="`background-image:url(${item.image})`">
						<div class="textBox">
							<p>{{item.title}}</p>
							<p>{{item.business_time + ' - ' + item.end_time}}</p>
							<div :class="`ribbon ${item.activity_status==1 ? '' : 'grayRibbn' }`">
								{{item.activity_status == 1 ? (lang==6?'进行中':'In rogress') : (lang==6?'已结束':'END')}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-pagination background layout="prev, pager, next" :page-size="limit" :total="total" :current-page="page"
			@current-change="changePage">
		</el-pagination>
	</div>
</template>

<script>
	import mixinLang from '../../components/mixin.js'
	import selectBox from '../../components/selectBox.vue'
	export default {
		metaInfo: {
			title: '宝爱捷(中国)汽车投资有限公司', // set a title
			meta: [{ // set meta
					name: 'keyWords',
					content: '宝马活动，奥迪活动，保时捷活动，宾利活动，兰博基尼活动，mini活动，盛大开幕，豪车活动，品牌活动，集团活动，宝爱捷活动，奥保行，宾捷，捷骏，骏宝行'
				},
				{
					name: 'description',
					content: ''
				}
			]
		},
		mixins: [mixinLang],
		components: {
			selectBox
		},
		data() {
			return {
				brand_id: '',
				page: 1,
				limit: 4,
				total: 1,
				list: [],
				brandList: []
			}
		},
		methods: {
			changePage(page) {
				this.page = page
				this.getActivityList()
			},
			getInfo() {
				this.getActivityList()
				// 获取品牌列表
				this.$api.getBrandList({
					cate_no: 'b4766587'
				}, {
					page: 1,
					limit: 100
				}).then(res => {
					this.brandList = res.list
				})
			},
			// 活动列表
			getActivityList(e = {}) {
				this.$api.getActivityList({
					cate_no: 'fdswset81',
					form: 'all',
					brand_id: this.brand_id,
					city_id: e.c ? e.c : '',
					business_time: e.d ? e.d : ''
				}, {
					page: this.page,
					limit: this.limit
				}).then(res => {
					this.list = [res.list]
					this.total = Number(res.paginated.count)
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		background-color: #F1F1F1;
		padding: 50px 0;
		position: relative;

		>div {
			padding: 50px 100px;
		}
	}

	.content {
		margin-top: 60px;

		.title-flexBox {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 50px;
		}

		.right {
			.el-select {
				width: 100%;
				border: none;
				border-bottom: 1px solid #999999;
				margin-top: 20px;
				font-family: SourceHanSansCN-Regular, sans-serif;
				font-size: 20px;
				font-weight: normal;
				color: #666666;
			}

			/deep/.el-input__inner {
				width: 100%;
				background-color: rgba(0, 0, 0, 0);
				border: none;
				font-family: SourceHanSansCN-Regular, sans-serif;
				font-size: 20px;
				font-weight: normal;
				color: #666666;

				&::placeholder {
					color: #666666;
				}
			}

			/deep/.el-input__icon {
				font-size: 20px;
				color: #666666;
			}
		}

		.bottom-content {
			position: relative;
			width: 100%;

			.box {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				position: relative;
				height: 1310px;
				cursor: pointer;

				.item {
					position: absolute;
					height: 380px;
					width: 840px;
					// background-image: url(../../assets/brandStory/introduce/bg.png);
					background-size: cover;
					margin-bottom: 30px;

					&::after {
						content: '';
						display: block;
						position: absolute;
						top: 20px;
						left: 20px;
						width: calc(100% - 40px);
						height: calc(100% - 40px);
						border: 2px solid #FFF;
					}

					&:nth-child(odd) {
						left: 0;
					}

					&:nth-child(even) {
						right: 0;
					}

					&:nth-child(1) {
						height: 380px !important;
						top: 0;
					}

					&:nth-child(2) {
						height: 780px;
						top: 0;
					}

					&:nth-child(3) {
						height: 780px;
						top: 410px;
					}

					&:nth-child(4) {
						top: 810px;
					}
				}
			}


		}
	}

	.textBox {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 120px;
		background: rgba(24, 24, 24, 0.85);
		font-family: SourceHanSansCN-Normal, sans-serif;
		font-size: 18px;
		font-weight: 350;
		padding: 0 50px;
		color: #FFF;

		p {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		p:nth-child(1) {
			font-family: SourceHanSansCN-Medium, sans-serif;
			font-size: 24px;
			font-weight: 500;
			margin: 20px 0 10px 0;
		}

		.ribbon {
			position: absolute;
			width: 100px;
			height: 32px;
			background: url(../../assets/activity/ribbon-red.png) no-repeat top/100% 100%;
			color: #FFF;
			font-family: SourceHanSansCN-Bold, sans-serif;
			font-size: 18px;
			font-weight: bold;
			right: 0;
			bottom: 20px;
			text-align: center;
			line-height: 32px;
			z-index: 999;
			text-transform: uppercase;
		}

		.grayRibbn {
			background: url(../../assets/activity/ribbon-gray.png) no-repeat top/100% 100%;
		}
	}

	.el-pagination {
		text-align: center;
		// margin: 100px 0;

		/deep/ .active {
			background-color: #97233F !important;
		}

		/deep/.el-pager li {
			height: 36px !important;
			line-height: 36px !important;
			font-size: 18px !important;
			padding: 0 8px !important;
		}
	}

	/deep/ .el-select-dropdown__item {
		font-family: SourceHanSansCN-Regular, sans-serif;
		font-size: 20px !important;
		font-weight: normal;
		color: #666666;
		height: 60px !important;
		line-height: 60px;
	}
</style>